import { useEffect, useState } from 'react'
import { friendlyTimeInterval } from "../lib/Util"

function Dateline({ timestamp, isLinked }) {
  const [justText, setJustText] = useState(false)
  const [timestampComponent, setTimestampComponent] = useState(null)

  // Otherwise state mismatch on production because of different time calculations
  useEffect(() => {
    const friendlyTimestamp = friendlyTimeInterval(timestamp, justText)

    const friendlyTimestampComponent = isLinked ?
      <a onClick={() => (setJustText(!justText))}>{friendlyTimestamp}</a> :
      friendlyTimestamp

    setTimestampComponent(friendlyTimestampComponent)
  }, [timestamp, isLinked, justText])

  return (
    <span className="timestamp">{ timestampComponent }</span>
  )
}

export default Dateline
