import { urlUserProfile } from "../lib/urlHelpers"

function UserpicLink({ user, isLinked }) {
  const imgTag = <img className="userpic" src={user.image} />
  const link = user.link || urlUserProfile(user.nickname)
  if (isLinked && link) {
    return(
      <a href={link}>
        {imgTag}
      </a>
    )
  } else {
    return imgTag
  }
}

export default UserpicLink
